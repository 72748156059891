<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('jointogroup.activaexam') }}
                    
                    div.text-center.mx-auto
                    
                    div
                        nav.breadcrumb-one(aria-label="breadcrumb")
                            ol.breadcrumb
                              li.breadcrumb-item
                                a(href="javascript:void(0);") {{ $t('jointogroup.dashboard') }}
                              li.breadcrumb-item.ctive(aria-current="page") {{ $t('jointogroup.joingroup') }}

                div.row.mt-5.pb-5
                    div.col-lg-6.col-md-6.mx-auto.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row.mt-4
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h3 {{ $t('jointogroup.joingroup2') }}
                                div.widget-content.widget-content-area
                                    div.col-md-12
                                        div.form-group
                                            label(for="") {{ $t('jointogroup.capturegroupcode') }}
                                            input.form-control(type="text" id="" :placeholder="$t('activateexam.code')" v-model="sCode" )
                                  
                                        
                                        a.btn.btn-primary(@click="Join")  {{ $t('btns.btnjoin') }} 
                                            i
                                                svg.feather.feather-power(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                                    path(d="M18.36 6.64a9 9 0 1 1-12.73 0")
                                                    line(x1="12" y1="2" x2="12" y2="12")


</template>
<script>
/* eslint-disable */
export default {
    name: 'JoinToGroup',
    data() {
        return {
            sCode: ''
        }
    },
    methods: {
        async Join() {
            this.openLoading(this.$t('msg.activecode'));
            var model= {
                idExam: this.idExam,
                sCode: this.sCode,
                idPerson: localStorage.CertIdP
            }

            await axios.post(apiURL + 'Group/AddUserToGroup?idPerson=' + localStorage.CertIdP + '&sKeyGroup=' + this.sCode, null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                //this.$parent.getMyExams();
                //Swal.fire('Datos Guardados correctamente')
                Swal.fire(this.$t('msg.datasave'))
                
                router.push('/student/studenthome')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )            
        }
        

    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
    }
        

}
</script>                                                              